import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
//import { useFirebase } from "gatsby-plugin-firebase"
import firebase from "gatsby-plugin-firebase"

  const IndexPage = ({ data }) => {
    
    //useFirebase(firebase => {
    React.useEffect(() => {
      firebase
        .analytics()
        .logEvent("visited index page")
        
    }, [])

  
    return  <Layout>
      <SiteMetadata title="travel - stories | LostFalcon" description="Have an indepth knowledge of your unexplored places, by just swiping the cards" />
      <Hero />
      <div className="bg-gray-100 py-12 lg:py-16">
        {data.site.siteMetadata.related && data.site.siteMetadata.related.length > 0 ? (
          <Cards items={data.site.siteMetadata.related} />
        ) : (
          <div className="container">No stories found.</div>
        )}
      </div>
    </Layout>

}

export default IndexPage

export const query = graphql`
    query {
        site {
            siteMetadata {
                    related {
                        ...PortfolioCard2
                    }
            }
        }
    }
`